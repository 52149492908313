import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Ruby Stars" />
    <h1>Hi beautiful people ;)</h1>
    <p>Lets hang out and have some fun tonight.</p>
<p><a href="https://www.w3schools.com/">Visit W3Schools.com!</a></p>
    <p>Let's make this day special.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go Ahead</Link> <br />
      <Link to="/using-typescript/">Go to another link.</Link>
    </p>
  </Layout>
)

export default IndexPage
